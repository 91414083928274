<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <b-row>
      <b-col cols="12">
        <web-heading />
      </b-col>
    </b-row>
    <b-card-body class="p-3">
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <feather-icon
            icon="AwardIcon"
            size="34"
            class="mr-50 text-primary"
          />
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{ game.c_string_swe }}
            <b-badge
              v-if="game.record_status===0"
              variant="light-warning"
              class="mr-1"
            >
              {{ $t('UNPUBLISHED') }}
            </b-badge>
            <b-badge
              :variant="`light-${resolveGameStatusVariant(game).variant}`"
            >
              {{ resolveGameStatusVariant(game).text }}
            </b-badge>
          </b-card-title>
          <b-card-text class="mb-0">
            <p v-html="game.visionstatement" />
          </b-card-text>
        </div>
      </div>
      <!--/ metting header -->
      <!-- start date -->
      <b-media
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mt-0 pt-50">
            {{ formatDateTime(game.startdate) }}
          </h6>
        </b-media-body>
      </b-media>
      <b-media
        no-body
      >
        <!-- end date -->
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mt-0 pt-50">
            {{ formatDateTime(game.enddate) }}
          </h6>
        </b-media-body>
      </b-media>
      <!-- avatar group -->
      <b-avatar-group
        class="mt-2 pt-50"
        size="33"
      >
        <h5 class="align-self-center cursor-pointer ml-1 mb-0">
          {{ game.usersCount }} {{ $t('PLAYERS') }}  -  {{ game.groupCount }} {{ $t('TEAMS') }}
        </h5>
      </b-avatar-group>
      <div class="p-0 mt-2">
        <h5 class="align-self-center cursor-pointer mb-1">
          {{ $t('JOIN_EXISTING_TEAM') }}
        </h5>
        <p>{{ $t('MESSAGE.ONBOARDING_HEADER_1') }}</p>
        <h5 class="align-self-center cursor-pointer mb-1">
          {{ $t('TO_CREATE_A_NEW_TEAM_AND_ADD_MEMBERS') }}
        </h5>
        <p>{{ $t('MESSAGE.ONBOARDING_HEADER_2') }}</p>
      </div>
    </b-card-body>
    <div class="p-0">
      <b-row v-if="game.record_status!==0">
        <b-col
          v-for="(group,index) in game.groups"
          :key="index"
          lg="4"
          md="6"
          :title="group.groupname"
        >
          <b-card class="card-app-design">

            <!-- design group -->
            <div class="design-group">
              <h6 class="section-label">
                {{ mapTeamRoleToVariant(group.groupcomplevel).description_2 }}
              </h6>
              <b-badge
                :key="index"
                :variant="changeVariant(index)"
                class="mr-1"
              >
                {{ group.groupname }}
              </b-badge>
            </div>
            <div class="design-group">
              <h6 class="section-label">
                {{ mapTeamRoleToVariant(group.groupcomplevel).description_1 }}
              </h6>
              <b-badge
                v-for="(user,userIndex) in group.users"
                :key="userIndex"
                :variant="changeVariant(index)"
                class="mr-1"
              >
                {{ user.firstname + ' ' + user.lastname.charAt(0) }}
              </b-badge>
            </div>
            <!--/ design group -->
            <!-- button -->
            <b-button
              v-show="game.gametype===1"
              v-if="((group.groupcomplevel!='-2') && canEditTeam)"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="changeButtonVariant(index)"
              @click="joinTeam(group,changeButtonVariant(index))"
            >
              {{ $t('JOIN_TEAM') }}
            </b-button>
          </b-card>
        </b-col>
        <b-col
          lg="4"
          md="6"
        >
          <onboarding-new-group
            v-if="canEditTeam"
            :competition-group="competitionGroup"
            :competition="game"
          />
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BButton,
  BAvatar, BAvatarGroup, VBTooltip, BMediaBody, BRow, BCol, BBadge,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/named
import { mapActions } from 'vuex'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { countingObject, canEditDeleteGame, mapTeamRoleToVariant } from '@/constants/utils'
import constants from '@/constants/static.json'
import WebHeading from '../utility/WebHeading.vue'
import OnboardingNewGroup from './OnboardingNewGroup.vue'
// eslint-disable-next-line import/named
import useGameList from '../../games/game-list/useGameList'

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BRow,
    BCol,
    BBadge,
    BButton,
    OnboardingNewGroup,
    WebHeading,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      game: {
        ID: 0,
        encrypt_id: '',
        c_string_swe: '',
        startdate: '',
        enddate: '',
        starttime: '',
        endtime: '',
        visionstatement: '',
        record_status: 0,
        usercount: '0',
        groups: [],
        gametype: 0,
      },
      canEditTeam: true,
      competitionGroup: {},
    }
  },
  created() {
    this.fetchGame()
  },
  mounted() {
    this.$i18n.locale = constants.LOCALE.SWEDISH
  },
  setup() {
    const {
      resolveGameStatusVariant,
    } = useGameList()

    return {
      resolveGameStatusVariant,
      mapTeamRoleToVariant,
    }
  },
  methods: {
    ...mapActions('onboarding', ['fetchAllGame']),
    fetchGame() {
      if (router.currentRoute.params.id) {
        this.encrypt_id = router.currentRoute.params.id
        this.fetchAllGame(this.encrypt_id).then(response => {
          if (response.data) {
            const { data } = response
            this.game = data
            this.$i18n.locale = this.game.language
            this.game.groups = data.groups
            const resuts = countingObject(this.game.groups)
            this.canEditTeam = canEditDeleteGame(this.game)
            this.game.usersCount = resuts.usersCount
            this.game.groupCount = resuts.groupCount
            if (this.game.gametype === 2) {
              const group = this.game.groups.find(i => i.groupcomplevel !== -2)
              if (group) {
                this.competitionGroup = group
                this.competitionGroup.variant = 'success'
              }
            }
          }
        })
      }
    },
    userCount(data) {
      let count = 0
      data.groups.forEach(group => {
        group.users.forEach(() => {
          // eslint-disable-next-line no-plusplus
          count++
        })
      })
      this.game.usercount = count
    },
    joinTeam(data, variant) {
      this.competitionGroup = data
      this.competitionGroup.variant = variant
      document.getElementById('id-firstname').focus()
    },
  },
}
</script>
